import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { isObject } from '@daikin-tic/dxone-com-lib';

import { QUEUE, QUEUE_OPTIONS } from '../models/sop-group.model';
import { MesRequestUsecase } from '../usecases/mes-request.usecase';

@Injectable()
export class MesRequestInterceptor implements HttpInterceptor {
  constructor(private _mesRequestUsecase: MesRequestUsecase) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.context.get(QUEUE)) {
      return this.executeApi(req);
    }
    if (req.url.includes('GetOrderListBL')) {
      this._mesRequestUsecase.clearRequestQueue();
    }

    return next.handle(req);
  }

  private executeApi(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    if (isObject(req.body)) {
      const body = { ...req.body };
      this._mesRequestUsecase.requestEnqueue(req.clone({ body }));
    }
    const params = req.context.get(QUEUE_OPTIONS).params;
    const apis: string[] = ['GetInventoryList', 'GetRecordComment', 'GetSOPObjAchvAndExecutableList'];
    return of(new HttpResponse({ body: apis.find(api => req.url.includes(api)) ? [params] : params }));
  }
}
