import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SopGroupAchievementReadParams, SopGroupReadParams } from '../models/sop-group.model';
import { SopFormulaReadParams, SopLabelValueReadParams, SopObjectReadParams, TagValueReadParams } from '../models/sop-object.model';

type SopGrpRequestParams = SopGroupAchievementReadParams | SopGroupReadParams;
type SopObjRequestParams = SopObjectReadParams | SopLabelValueReadParams | TagValueReadParams | SopFormulaReadParams;
type SopRequestParams = SopGrpRequestParams | SopObjRequestParams;
type RequestError = { name: string; error: HttpErrorResponse };

export abstract class MesRequestUsecase {
  abstract get requestError$(): Observable<RequestError | undefined>;

  abstract requestEnqueue(request: HttpRequest<unknown>): void;

  abstract cancelRequestQueue(apiName: string, params: SopRequestParams, priority: number): void;
  abstract clearRequestQueue(priority?: number): void;
  abstract clearRequestError(): void;
  abstract addRequestError(name: string, error: HttpErrorResponse): void;
}
