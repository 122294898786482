import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsonBigInt from 'json-bigint';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HIDE_PROGRESS } from '@daikin-tic/dxone-com-lib';

import {
  CreatedRecordComment,
  QUEUE,
  QUEUE_OPTIONS,
  RecordComment,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SopObjectAchievement,
  parseCreatedRecordComment,
  parseSopObjectAchievement,
} from '../models/sop-group.model';
import {
  Confirmation,
  ConfirmationReadParams,
  Equipment,
  EquipmentReadParams,
  Instruct,
  InstructReadParams,
  Inventory,
  InventoryReadParams,
  PiPastData,
  PiPastDataReadParams,
  ProcessInput,
  ProcessInputAchievement,
  ProcessInputAchievementsReadParams,
  ProcessInputReadParams,
  ProcessInputVolume,
  ProcessInputVolumeReadParams,
  ProcessOutputVolume,
  ProcessOutputVolumeReadParams,
  SopFormula,
  SopFormulaReadParams,
  SopLabelValue,
  SopLabelValueReadParams,
  SopObjectAchievementUpdateParams,
  SopObjectDefinition,
  SopObjectDefinitionReadParams,
  SopObjectInputItemUpdateParams,
  SopObjectLogBookUpdateParams,
  SopObjectMultipleUpdateParams,
  SopObjectReadParams,
  SopObjectSkipParams,
  SopObjectStatus,
  SopObjectUpdateParams,
  SumOfInputVolume,
  SumOfInputVolumeReadParams,
  SumOfYield,
  SumOfYieldReadParams,
  TagValue,
  TagValueReadParams,
  UpdatedSopObject,
  UpdatedSopObjectAchievement,
  UpdatedSopObjectInputItem,
  UpdatedSopObjectLogBook,
  UpdatedSopObjectMultiple,
  UpdatedSopObjectSkip,
  parseConfirmation,
  parseEquipment,
  parseInstruct,
  parseProcessInput,
  parseProcessInputAchievement,
  parseProcessInputVolume,
  parseProcessOutputVolume,
  parseSopObjectDefinition,
  parseSopObjectSkip,
  parseSumOfInputVolume,
  parseSumOfYield,
  parseUpdateSopObjectAchievement,
  parseUpdatedSopObject,
  parseUpdatedSopObjectInputItem,
  parseUpdatedSopObjectLogBook,
  parseUpdatedSopObjectMultiple,
} from '../models/sop-object.model';
import { SopObjectGateway } from '../usecases/sop-object.gateway';

@Injectable()
export class SopObjectService extends SopObjectGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listSopObjectDefinitions(params: SopObjectDefinitionReadParams): Observable<SopObjectDefinition[]> {
    const body = {
      sqlName: 'GetSOPObjectDefine',
      paramList: [
        { name: '@InstructNo', value: params.instructNo },
        { name: '@ProcessCode', value: params.processCode },
        { name: '@WorkflowID', value: params.workflowId },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseSopObjectDefinition(value))));
  }

  listConfirmations(params: ConfirmationReadParams): Observable<Confirmation[]> {
    const body = {
      sqlName: 'GetConfirmationList',
      paramList: [
        { name: '@InstructNo', value: params.instructNo },
        { name: '@ConfirmKind', value: params.confirmKind },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseConfirmation(value))));
  }

  listEquipments(params: EquipmentReadParams): Observable<Equipment[]> {
    const body = {
      sqlName: 'GetEquipment',
      paramList: [
        { name: '@EquipmentCode', value: params.equipmentCode },
        { name: '@UpdatedStatus', value: params.updatedStatus },
        { name: '@LogBookTreatMode', value: params.logBookTreatMode },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseEquipment(value))));
  }

  listProcessInputs(params: ProcessInputReadParams): Observable<ProcessInput[]> {
    const body = {
      sqlName: 'GetProcessInput',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@ObjID', value: params.objId },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseProcessInput(value))));
  }

  listProcessInputAchievements(params: ProcessInputAchievementsReadParams): Observable<ProcessInputAchievement[]> {
    const body = {
      sqlName: 'GetProcessInputAchievement',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@BatchNo', value: params.batchNo },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseProcessInputAchievement(value))));
  }

  listInventories(params: InventoryReadParams, priority: number): Observable<Inventory[]> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['RunTimeInstructID']: params.runTimeInstructId,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
    };
    const options = {
      context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }),
    };
    return this._http.post<Inventory[]>('api/mes/GetSopData/GetInventoryList', body, options);
  }

  listObjectRecordComments(params: RecordCommentReadParams, priority: number): Observable<RecordComment[]> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['AchvOrder']: params.achvOrder,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }) };
    return this._http.post<RecordComment[]>('api/mes/Comment/GetRecordComment', body, options);
  }

  getSopObjectAchievement(params: SopObjectReadParams): Observable<SopObjectAchievement[]> {
    const body = {
      sqlName: 'GetSOPObjectAchievement',
      paramList: [
        { name: '@InstructNo', value: params.instructNo },
        { name: '@ProcessCode', value: params.processCode },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@WorkflowID', value: params.workflowId },
        { name: '@ObjID', value: params.objId },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseSopObjectAchievement(value))));
  }

  getSumOfYield(params: SumOfYieldReadParams): Observable<SumOfYield[]> {
    const body = {
      sqlName: 'GetSumOfYield',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@OutputItemCode', value: params.outputItemCode },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseSumOfYield(value))));
  }

  getSumOfInputVolume(params: SumOfInputVolumeReadParams): Observable<SumOfInputVolume[]> {
    const body = {
      sqlName: 'GetSumOfInputVolume',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@InputItemCode', value: params.inputItemCode },
        { name: '@InputOrder', value: params.inputOrder },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseSumOfInputVolume(value))));
  }

  getInstruct(params: InstructReadParams): Observable<Instruct[]> {
    const body = {
      sqlName: 'GetInstruct',
      paramList: [{ name: '@InstructNo', value: params.instructNo }],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseInstruct(value))));
  }

  getProcessInputVolume(params: ProcessInputVolumeReadParams): Observable<ProcessInputVolume[]> {
    const body = {
      sqlName: 'GetProcessInputVolume',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@InputItemCode', value: params.inputItemCode },
        { name: '@InputOrder', value: params.inputOrder },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseProcessInputVolume(value))));
  }

  getProcessOutputVolume(params: ProcessOutputVolumeReadParams): Observable<ProcessOutputVolume[]> {
    const body = {
      sqlName: 'GetProcessOutputVolume',
      paramList: [
        { name: '@RunTimeInstructID', value: params.runTimeInstructId },
        { name: '@ItemCode', value: params.itemCode },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseProcessOutputVolume(value))));
  }

  getSopObjectStatus(params: SopObjectReadParams, priority: number): Observable<SopObjectStatus> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }) };
    return this._http.post<SopObjectStatus>('api/mes/GetSopData/GetSopObjectStatus', body, options);
  }

  getSopLabelValue(
    params: SopLabelValueReadParams,
    priority: number,
    objId: string,
    labelId: string,
    hideProgress = true,
  ): Observable<SopLabelValue> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['SOPLabelName']: params.sopLabelName,
      ['TotalValueFlg']: params.totalValueFlg,
      ['ReferenceMode']: params.referenceMode,
      ['AchvOrder']: params.achvOrder,
    };
    const options = {
      context: new HttpContext()
        .set(QUEUE, true)
        .set(HIDE_PROGRESS, hideProgress)
        .set(QUEUE_OPTIONS, { params: { ...params, objId, labelId }, priority }),
    };
    return this._http.post<SopLabelValue>('api/mes/GetSopData/GetSopLabelValue', body, options);
  }

  getTagValue(params: TagValueReadParams, priority: number, objId: string): Observable<TagValue> {
    const body = {
      ['RunTimeInstructID']: params.runTimeInstructId,
      ['BatchNo']: params.batchNo,
      ['TagName']: params.tagName,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(QUEUE_OPTIONS, { params: { ...params, objId }, priority }) };
    return this._http.post<TagValue>('api/mes/GetSopData/GetTagValue', body, options);
  }

  calculateFormula(
    params: SopFormulaReadParams,
    priority: number,
    objId: string,
    formulaId: string,
    hideProgress = true,
  ): Observable<SopFormula> {
    const body = {
      ['RunTimeInstructID']: params.runTimeInstructId,
      ['BatchNo']: params.batchNo,
      ['Formula']: params.formula,
    };
    const options = {
      context: new HttpContext()
        .set(QUEUE, true)
        .set(HIDE_PROGRESS, hideProgress)
        .set(QUEUE_OPTIONS, { params: { ...params, objId, formulaId }, priority }),
    };
    return this._http.post<SopFormula>('api/mes/GetSopData/CalculateFormula', body, options);
  }

  getPiPastData(params: PiPastDataReadParams, priority: number, objId: string): Observable<PiPastData> {
    const body = {
      ['TagName']: params.tagName,
      ['PIAFTime']: params.piAfTime,
      ['RetrievalMode']: params.retrievalMode,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(QUEUE_OPTIONS, { params: { ...params, objId }, priority }) };
    return this._http.post<PiPastData>('api/mes/GetSopData/GetShowPIPastData', body, options);
  }

  updateSopObject(params: SopObjectUpdateParams): Observable<UpdatedSopObject> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['InstructValue']: params.instructValue,
      ['Value']: params.value,
      ['Value2']: params.value2,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
      ['NextLabel']: params.nextLabel,
      ['Comment']: params.comment,
      ['DeviationID']: params.deviationId,
      ['DeviationComment']: params.deviationComment,
      ['LimitValue']: params.limitValue,
      ['GlobalTagName']: params.globalTagName,
      ['RunTimeInstructID']: params.runTimeInstructId,
      ['WorkflowSet']: params.workflowSet,
      ['WorkflowSetSeqNo']: params.workflowSetSeqNo,
      ['OutputItemCode']: params.outputItemCode,
      ['FinanceDate']: params.financeDate,
      ['ContainerNo']: params.containerNo,
      ['PalletNo']: params.palletNo,
      ['EffectiveNumber']: params.effectiveNumber,
    };
    return this._http.post<unknown>('api/mes/UploadResult/UploadSopBL', body).pipe(map(value => parseUpdatedSopObject(value)));
  }

  updateSopObjectLogBook(params: SopObjectLogBookUpdateParams): Observable<UpdatedSopObjectLogBook> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['InstructValue']: params.instructValue,
      ['Value']: params.value,
      ['Value2']: params.value2,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
      ['NextLabel']: params.nextLabel,
      ['EquipmentStatusCode']: params.equipmentStatusCode,
      ['Comment']: params.comment,
    };
    return this._http.post<unknown>('api/mes/UploadResult/UploadSopLogBook', body).pipe(map(value => parseUpdatedSopObjectLogBook(value)));
  }

  updateSopObjectMultiple(params: SopObjectMultipleUpdateParams[]): Observable<UpdatedSopObjectMultiple[]> {
    const body = params.map(param => ({
      ['InstructNo']: param.instructNo,
      ['ProcessCode']: param.processCode,
      ['BatchNo']: param.batchNo,
      ['WorkflowID']: param.workflowId,
      ['ObjID']: param.objId,
      ['InstructValue']: param.instructValue,
      ['Value']: param.value,
      ['UpdaterCode']: param.updaterCode,
      ['UpdateTime']: param.updateTime,
      ['Comment']: param.comment,
      ['DeviationID']: param.deviationId,
      ['DeviationComment']: param.deviationComment,
      ['LimitValue']: param.limitValue,
      ['GlobalTagName']: param.globalTagName,
      ['RunTimeInstructID']: param.runTimeInstructId,
      ['WorkflowSet']: param.workflowSet,
      ['WorkflowSetSeqNo']: param.workflowSetSeqNo,
      ['OutputItemCode']: param.outputItemCode,
      ['FinanceDate']: param.financeDate,
      ['ContainerNo']: param.containerNo,
      ['PalletNo']: param.palletNo,
      ['EffectiveNumber']: param.effectiveNumber,
    }));
    return this._http
      .post<unknown[]>('api/mes/UploadResult/UploadSopMultipleBL', body)
      .pipe(map(values => values.map(value => parseUpdatedSopObjectMultiple(value))));
  }

  updateSopObjectInputItem(params: SopObjectInputItemUpdateParams): Observable<UpdatedSopObjectInputItem> {
    const paramlist = params.paramList.map(param => {
      return {
        ['InputItemCode']: param.inputItemCode,
        ['ItemName']: param.itemName,
        ['LotNo']: param.lotNo,
        ['LotSeqNo']: param.lotSeqNo,
        ['EditionNo']: param.editionNo,
        ['InputOrder']: param.inputOrder,
        ['DivisionNo']: param.divisionNo,
        ['DivisionBranchNo']: param.divisionBranchNo,
        ['PalletNo']: param.palletNo,
        ['ContainerNo']: param.containerNo,
        ['BaleNo']: param.baleNo,
        ['WeightUnitCode']: param.weightUnitCode,
        ['UnitName']: param.unitName,
        ['TakeoutValue']: param.takeoutValue,
        ['TakeoutValueOld']: param.takeoutValueOld,
        ['AchvAddWeight']: param.achvAddWeight,
        ['AchvAddWeightOld']: param.achvAddWeightOld,
        ['RemainValue']: param.remainValue,
        ['RemainValueOld']: param.remainValueOld,
        ['AbolishValue']: param.abolishValue,
        ['AbolishValueOld']: param.abolishValueOld,
        ['DifferenceVolume']: param.differenceVolume,
        ['DifferenceVolumeOld']: param.differenceVolumeOld,
        ['RecipeOrder']: param.recipeOrder,
        ['TreatMode']: param.treatMode,
        ['AchvFlg']: param.achvFlg,
        ['InventoryZoneCode']: param.inventoryZoneCode,
        ['StockWeightUnitRatio']: param.stockWeightUnitRatio,
        ['IsUnloadContainer']: param.isUnloadContainer,
        ['InputItemComment']: param.inputItemComment,
        ['DeviationID']: param.deviationId,
        ['DeviationComment']: param.deviationComment,
        ['LimitValue']: param.limitValue,
      };
    });
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['InstructValue']: params.instructValue,
      ['Value']: params.value,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
      ['NextLabel']: params.nextLabel,
      ['EquipmentStatusCode']: params.equipmentStatusCode,
      ['RemainBackIsChecked']: params.remainBackIsChecked,
      ['Comment']: params.comment,
      ['Paramlist']: paramlist,
    };
    const options = { headers: new HttpHeaders({ ['Content-Type']: 'application/json' }) };
    return this._http
      .post<unknown>('api/mes/UploadResult/UploadSopInputItem', jsonBigInt.stringify(body), options)
      .pipe(map(value => parseUpdatedSopObjectInputItem(value)));
  }

  updateSopObjectSkip(params: SopObjectSkipParams): Observable<UpdatedSopObjectSkip> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['InstructValue']: params.instructValue,
      ['Value']: params.value,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
      ['NextLabel']: params.nextLabel,
      ['Comment']: params.comment,
      ['DeviationID']: params.deviationId,
      ['DeviationComment']: params.deviationComment,
    };
    return this._http.post<unknown>('api/mes/UploadResult/SOPSkip', body).pipe(map(value => parseSopObjectSkip(value)));
  }

  updateSopObjectAchievement(params: SopObjectAchievementUpdateParams): Observable<UpdatedSopObjectAchievement> {
    const body = [
      {
        ['RunTimeInstructID']: params.runTimeInstructId,
        ['WorkflowSet']: params.workflowSet,
        ['WorkflowSetSeqNo']: params.workflowSetSeqNo,
        ['ObjID']: params.objId,
        ['BatchNo']: params.batchNo,
        ['AchvOrder']: params.achvOrder,
        ['InstructNo']: params.instructNo,
        ['ProcessCode']: params.processCode,
        ['WorkflowID']: params.workflowId,
        ['SOPOrder']: params.sopOrder,
        ['AchvValue1']: params.achvValue1,
        ['AchvValue2']: params.achvValue2,
        ['AchvValue3']: params.achvValue3,
        ['AchvValue4']: params.achvValue4,
        ['AchvValue5']: params.achvValue5,
        ['AchvValue6']: params.achvValue6,
        ['AchvDate']: params.achvDate,
        ['UpdaterName']: params.updaterName,
        ['Comment']: params.comment,
        ['DeviationID']: params.deviationId,
        ['UpdaterCode']: params.updaterCode,
        ['UpdateTime']: params.updateTime,
      },
    ];
    return this._http
      .post<unknown[]>('api/mes/UploadResult/ModifySOPAchievement', body)
      .pipe(map(values => parseUpdateSopObjectAchievement(values[0])));
  }

  createObjectRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['AchvOrder']: params.achvOrder,
      ['Comment']: params.comment,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
    };
    return this._http.post<unknown>('api/mes/Comment/AddRecordComment', body).pipe(map(value => parseCreatedRecordComment(value)));
  }
}
