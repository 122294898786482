import { Observable } from 'rxjs';

import {
  CreatedRecordComment,
  Order,
  OrderReadParams,
  RecordComment,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SopGroupAchievement,
  SopGroupAchievementReadParams,
  SopGroupReadParams,
  SopGroupStatus,
  SopObjectAchievement,
} from '../models/sop-group.model';

export abstract class SopGroupGateway {
  abstract listOrders(params: OrderReadParams): Observable<Order[]>;
  abstract listSopGroupAchievements(params: SopGroupAchievementReadParams, priority: number): Observable<SopGroupAchievement[]>;
  abstract listSopObjectAchievements(params: SopGroupReadParams): Observable<SopObjectAchievement[]>;
  abstract listGroupRecordComments(params: RecordCommentReadParams, priority: number): Observable<RecordComment[]>;
  abstract getSopGroupStatus(params: SopGroupReadParams, priority: number): Observable<SopGroupStatus>;
  abstract createGroupRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment>;
}
