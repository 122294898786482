import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsonBigInt from 'json-bigint';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { isObject, isString } from '@daikin-tic/dxone-com-lib';

const PARSE_TARGET_APIS = ['GetInventoryList', 'GetProcessInput', 'GetProcessInputAchievement'];
const PARSE_TARGET_PROPS = ['Volume', 'AchvAddWeight', 'UnitNumber', 'AvailableNumber'];

@Injectable()
export abstract class JsonParser {
  abstract parse(text: string): unknown;
}

@Injectable()
export class CustomJsonInterceptor implements HttpInterceptor {
  constructor(private _jsonParser: JsonParser) {}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (PARSE_TARGET_APIS.find(api => httpRequest.url.includes(api))) {
      return this.handleJsonResponse(httpRequest, next);
    }

    const body = httpRequest?.body;
    if (isObject(body) && isString(body['sqlName']) && PARSE_TARGET_APIS.find(api => body['sqlName'] === api)) {
      return this.handleJsonResponse(httpRequest, next);
    }
    return next.handle(httpRequest);
  }

  private handleJsonResponse(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    httpRequest = httpRequest.clone({ responseType: 'text' });
    return next.handle(httpRequest).pipe(
      map(event => this.parseSuccessResponse(event)),
      catchError((error: HttpErrorResponse) => this.parseErrorResponse(error)),
    );
  }

  private parseSuccessResponse(event: HttpEvent<unknown>): HttpEvent<unknown> {
    if (event instanceof HttpResponse && typeof event.body === 'string') {
      return event.clone({ body: this._jsonParser.parse(event.body) });
    }
    return event;
  }

  private parseErrorResponse(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (error instanceof HttpErrorResponse && typeof error.error === 'string') {
      const err = new HttpErrorResponse({ error: this._jsonParser.parse(error.error), status: error.status });
      return throwError(err);
    }
    return throwError(error);
  }
}

@Injectable()
export class CustomJsonParser implements JsonParser {
  parse(text: string): unknown {
    const bigParse = jsonBigInt.parse(text, reviver);
    return bigParse;
  }
}

function reviver(key: string, value: string | number): unknown {
  if (PARSE_TARGET_PROPS.includes(key)) {
    return value.toString();
  }
  return value;
}
