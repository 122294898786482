export const DEVIATION_ID = {
  input0002: 'INPUT0002',
  limit0001: 'LIMIT0001',
  limit0002: 'LIMIT0002',
  limit0004: 'LIMIT0004',
  limit0013: 'LIMIT0013',
  limit0018: 'LIMIT0018',
  limit0019: 'LIMIT0019',
  mod0001: 'MOD0001',
  skip0001: 'SKIP0001',
  unmatch0001: 'UNMATCH0001',
} as const;

export const DEVIATION_MANAGEMENT = {
  required: -1,
  notRequired: 0,
} as const;

export type Deviation = {
  deviationId: (typeof DEVIATION_ID)[keyof typeof DEVIATION_ID];
  isDeviation: (typeof DEVIATION_MANAGEMENT)[keyof typeof DEVIATION_MANAGEMENT];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDeviation(value: any): Deviation {
  return {
    deviationId: value.DeviationID,
    isDeviation: value.IsDeviation,
  };
}
