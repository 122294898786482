import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HIDE_PROGRESS } from '@daikin-tic/dxone-com-lib';

import {
  CreatedRecordComment,
  Order,
  OrderReadParams,
  QUEUE,
  QUEUE_OPTIONS,
  RecordComment,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SopGroupAchievement,
  SopGroupAchievementReadParams,
  SopGroupReadParams,
  SopGroupStatus,
  SopObjectAchievement,
  parseCreatedRecordComment,
  parseOrder,
  parseSopObjectAchievement,
} from '../models/sop-group.model';
import { SopGroupGateway } from '../usecases/sop-group.gateway';

@Injectable()
export class SopGroupService extends SopGroupGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listOrders(params: OrderReadParams): Observable<Order[]> {
    const body = {
      ['MainDepartmentCode']: params.mainDepartmentCode,
    };
    return this._http
      .post<unknown[]>('api/mes/GetSopData/GetOrderListBL', body)
      .pipe(map(values => values.map(value => parseOrder(value))));
  }

  listSopGroupAchievements(params: SopGroupAchievementReadParams, priority: number): Observable<SopGroupAchievement[]> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }) };
    return this._http.post<SopGroupAchievement[]>('api/mes/GetSopData/GetSOPObjAchvAndExecutableList', body, options);
  }

  listSopObjectAchievements(params: SopGroupReadParams): Observable<SopObjectAchievement[]> {
    const body = {
      sqlName: 'GetSOPObjectAchievementList',
      paramList: [
        { name: '@InstructNo', value: params.instructNo },
        { name: '@ProcessCode', value: params.processCode },
        { name: '@BatchNo', value: params.batchNo },
        { name: '@WorkflowID', value: params.workflowId },
      ],
    };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseSopObjectAchievement(value))));
  }

  listGroupRecordComments(params: RecordCommentReadParams, priority: number): Observable<RecordComment[]> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }) };
    return this._http.post<RecordComment[]>('api/mes/Comment/GetRecordComment', body, options);
  }

  getSopGroupStatus(params: SopGroupReadParams, priority: number): Observable<SopGroupStatus> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
    };
    const options = { context: new HttpContext().set(QUEUE, true).set(HIDE_PROGRESS, true).set(QUEUE_OPTIONS, { params, priority }) };
    return this._http.post<SopGroupStatus>('api/mes/GetSopData/GetSopGroupStatus', body, options);
  }

  createGroupRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment> {
    const body = {
      ['InstructNo']: params.instructNo,
      ['ProcessCode']: params.processCode,
      ['BatchNo']: params.batchNo,
      ['WorkflowID']: params.workflowId,
      ['ObjID']: params.objId,
      ['AchvOrder']: params.achvOrder,
      ['Comment']: params.comment,
      ['UpdaterCode']: params.updaterCode,
      ['UpdateTime']: params.updateTime,
    };
    return this._http.post<unknown>('api/mes/Comment/AddRecordComment', body).pipe(map(value => parseCreatedRecordComment(value)));
  }
}
