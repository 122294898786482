import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Deviation, parseDeviation } from '../models/deviation.model';
import { DeviationGateway } from '../usecases/deviation.gateway';

@Injectable()
export class DeviationService extends DeviationGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listDeviations(): Observable<Deviation[]> {
    const body = { sqlName: 'GetIsDeviation' };
    return this._http
      .post<unknown[]>('api/mes/executeSQL/ExecuteOutputSQLBL', body)
      .pipe(map(values => values.map(value => parseDeviation(value))));
  }
}
