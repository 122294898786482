import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap } from 'rxjs/operators';

import { AuthPayload, AuthState, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';
import { AuthUsecase } from '../usecases/auth.usecase';
import { DatadogGateway } from '../usecases/datadog.gateway';
import { ProgressUsecase } from '../usecases/progress.usecase';

@Injectable()
export class AuthInteractor extends AuthUsecase {
  get authState$(): Observable<AuthState> {
    return this._authState.pipe(filter(({ status }) => status !== 'none'));
  }
  get token$(): Observable<string> {
    return this._authGateway.currentSession().pipe(map(session => session.token));
  }
  get payload$(): Observable<AuthPayload> {
    return this._authGateway.currentSession().pipe(map(session => session.payload));
  }

  private readonly _authState = new BehaviorSubject<AuthState>({ status: 'none' });

  constructor(private _progressUsecase: ProgressUsecase, private _authGateway: AuthGateway, private _datadogGateway: DatadogGateway) {
    super();
    this._authGateway
      .currentAuthenticatedUser()
      .pipe(catchError(() => of(undefined)))
      .subscribe(user => this.setAuthState({ status: user ? 'signedIn' : 'signIn', user }));

    this._authState
      .pipe(
        filter(({ status }) => status === 'signedIn'),
        mergeMap(({ user }) => this.payload$.pipe(map(payload => ({ user, payload })))),
      )
      .subscribe(({ user, payload }) => {
        this._datadogGateway.setUser(user?.attributes?.email, payload.organizationId);
      });
  }

  signIn(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .signIn()
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  completeNewPassword(password: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .completeNewPassword(user, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          const username = user.challengeParam?.userAttributes.email;
          this.setAuthState({ status: 'signIn', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  signOut(): void {
    this._authGateway.signOut().subscribe(() => {
      this.setAuthState({ status: 'signIn' });
    });
  }

  forgotPassword(username: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPassword(username)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'resetPassword', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPasswordSubmit(username, code, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'signIn' });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  changePassword(oldPassword: string, newPassword: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .changePassword(user, oldPassword, newPassword)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  setAuthState(state: AuthState): void {
    this._authState.next(state);
  }
}
