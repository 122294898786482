import { HttpContextToken } from '@angular/common/http';
import dayjs from 'dayjs';

import { ImmutableMap } from '@daikin-tic/dxone-com-lib';

export const QUEUE = new HttpContextToken(() => false);
const defaultOptions = { params: {}, priority: 0 };
export const QUEUE_OPTIONS = new HttpContextToken(() => defaultOptions);

export const SOP_REQUEST_RESULT = {
  success: 0,
  error: -1,
} as const;
export type SopRequestResult = (typeof SOP_REQUEST_RESULT)[keyof typeof SOP_REQUEST_RESULT];

export const SOP_GROUP_WF_STATUS = {
  notStarted: 0,
  running: 1,
  completed: 2,
  forcedTermination: 3,
} as const;
type SopGroupWfStatus = (typeof SOP_GROUP_WF_STATUS)[keyof typeof SOP_GROUP_WF_STATUS];

export const SOP_OBJECT_STATUS = {
  notStarted: 0,
  completed: 1,
  skipped: 2,
  forcedTermination: 3,
} as const;
type SopObjStatus = (typeof SOP_OBJECT_STATUS)[keyof typeof SOP_OBJECT_STATUS];

export const SOP_EXECUTABLE_STATUS = {
  executable: 0,
  notExecutable: 1,
  deviation: 2,
} as const;
export type SopExecutableStatus = (typeof SOP_EXECUTABLE_STATUS)[keyof typeof SOP_EXECUTABLE_STATUS];

export const SOP_COMMENT_STATUS = {
  exist: -1,
  notExist: 0,
} as const;
type SopCommentStatus = (typeof SOP_COMMENT_STATUS)[keyof typeof SOP_COMMENT_STATUS];

export const SOP_DEVIATION_STATUS = {
  exist: -1,
  notExist: 0,
} as const;
type SopDeviationStatus = (typeof SOP_DEVIATION_STATUS)[keyof typeof SOP_DEVIATION_STATUS];

export type Order = {
  orderResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  processName: string;
  processOrder: number;
  runTimeInstructId: string;
  batchNo: number;
  issuedDate: string;
  startPlanDate: string;
  endPlanDate: string;
  startDate: string;
  endDate: string;
  itemCode: string;
  itemName: string;
  displayLotNo: string;
  recipeCode: string;
  status: number;
  instructStatus: number;
  workflowSet: string;
  departmentCode: string;
  workflowId: string;
  workflowName: string;
  displayOrder: number;
  wfStatus: SopGroupWfStatus;
  playerCode1: string;
  playerCode2: string;
  playerCode3: string;
  urlPath: string;
  hasRecordComment: SopCommentStatus;
  hasDeviation: SopDeviationStatus;
  kyComments: string;
  isExecutable: SopExecutableStatus;
  workflowSetSeqNo: number;
};

export type SopGroupAchievement = {
  wfAchvResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  processOrder: string;
  runTimeInstructId: string;
  batchNo: string;
  startDate: string;
  endDate: string;
  status: string;
  instructStatus: string;
  workflowId: string;
  wfStatus: SopGroupWfStatus;
  isExecutable: SopExecutableStatus;
  hasRecordComment: SopCommentStatus;
  hasDeviation: SopDeviationStatus;
};

export type SopObjectAchievement = {
  instructNo: string;
  processCode: string;
  processOrder: number;
  runTimeInstructId: string;
  batchNo: number;
  startDate: string;
  endDate: string;
  status: number;
  instructStatus: number;
  workflowId: string;
  wfStatus: SopGroupWfStatus;
  objId: string;
  sopOrder: number;
  achvOrder: number;
  revisionNo: number;
  objStatus: SopObjStatus;
  terminalCode: string;
  instructValue: string;
  achvValue1: string;
  achvValue2: string;
  achvValue3: string;
  achvValue4: string;
  achvValue5: string;
  achvValue6: string;
  updaterCode: string;
  updaterName: string;
  achvDate: string;
  hasRecordComment: SopCommentStatus;
  hasDeviation: SopDeviationStatus;
  workflowSetSeqNo: number;
  workflowSet: string;
  labelName: string;
};

export type SopGroupStatus = {
  isExecutable: SopExecutableStatus;
  status: number;
  instructStatus: number;
  wfStatus: SopGroupWfStatus;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
};

export type RecordComment = {
  recordCommentResult: SopRequestResult;
  errorMessage: string;
  inputDate: string;
  inputUserName: string;
  comment: string;
  commentType: string;
  commentTypeName: string;
  instructNo: string;
  itemCode: string;
  itemName: string;
  displayLotNo: string;
  processCode: string;
  processName: string;
  zoneName: string;
  workflowSet: string;
  workflowId: string;
  workflowName: string;
  batchNo: string;
  workflowSetSeqNo: string;
  achvOrder: string;
  sopOrder: string;
  workflowSetName: string;
};

export type CreatedRecordComment = {
  createCommentResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  achvOrder: number;
  comment: string;
  updaterCode: string;
  updateTime: string;
};

export type Comment = Pick<RecordComment, 'inputDate' | 'comment'> &
  Partial<Pick<RecordComment, 'inputUserName'> & Pick<CreatedRecordComment, 'updaterCode'>>;

export type GrpSyncStatus = { isSyncWfStatus?: boolean; isSyncWfExecutable?: boolean; isSyncWfComments?: boolean };

export type SopGroup = Order & GrpSyncStatus & { comments?: Comment[]; uid: string; version: number };

export type OrderReadParams = { mainDepartmentCode: string };
export type SopGroupAchievementReadParams = { instructNo: string; processCode: string };
export type SopGroupReadParams = { instructNo: string; processCode: string; batchNo: number; workflowId: string };
export type RecordCommentReadParams = SopGroupReadParams & { objId?: string; achvOrder?: number };
export type RecordCommentCreateParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  achvOrder: number;
  comment: string;
  updaterCode: string;
  updateTime: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseOrder(value: any): Order {
  return {
    orderResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    processName: value.ProcessName,
    processOrder: value.ProcessOrder,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    issuedDate: value.IssuedDate,
    startPlanDate: value.StartPlanDate,
    endPlanDate: value.EndPlanDate,
    startDate: value.StartDate,
    endDate: value.EndDate,
    itemCode: value.ItemCode,
    itemName: value.ItemName,
    displayLotNo: value.DisplayLotNo,
    recipeCode: value.RecipeCode,
    status: value.Status,
    instructStatus: value.InstructStatus,
    workflowSet: value.WorkflowSet,
    departmentCode: value.DepartmentCode,
    workflowId: value.WorkflowID,
    workflowName: value.WorkflowName,
    displayOrder: value.DisplayOrder,
    wfStatus: value.WFStatus,
    playerCode1: value.PlayerCode1,
    playerCode2: value.PlayerCode2,
    playerCode3: value.PlayerCode3,
    urlPath: value.URLPath,
    hasRecordComment: value.HasRecordComment,
    hasDeviation: value.HasDeviation,
    kyComments: value.KYComments,
    isExecutable: value.IsExecutable,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopGroupAchievement(value: any): SopGroupAchievement {
  return {
    wfAchvResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    processOrder: value.ProcessOrder,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    startDate: value.StartDate,
    endDate: value.EndDate,
    status: value.Status,
    instructStatus: value.InstructStatus,
    workflowId: value.WorkflowID,
    wfStatus: value.WFStatus,
    isExecutable: value.IsExecutable,
    hasRecordComment: value.HasRecordComment,
    hasDeviation: value.HasDeviation,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopObjectAchievement(value: any): SopObjectAchievement {
  return {
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    processOrder: value.ProcessOrder,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    startDate: value.StartDate,
    endDate: value.EndDate,
    status: value.Status,
    instructStatus: value.InstructStatus,
    workflowId: value.WorkflowID,
    wfStatus: value.WFStatus,
    objId: value.ObjID,
    sopOrder: value.SOPOrder,
    achvOrder: value.AchvOrder,
    revisionNo: value.RevisionNo,
    objStatus: value.ObjStat,
    terminalCode: value.TerminalCode,
    instructValue: value.InstructValue,
    achvValue1: value.AchvValue1,
    achvValue2: value.AchvValue2,
    achvValue3: value.AchvValue3,
    achvValue4: value.AchvValue4,
    achvValue5: value.AchvValue5,
    achvValue6: value.AchvValue6,
    updaterCode: value.UpdaterCode,
    updaterName: value.UpdaterName,
    achvDate: value.AchvDate,
    hasRecordComment: value.HasRecordComment,
    hasDeviation: value.HasDeviation,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
    workflowSet: value.WorkflowSet,
    labelName: value.LabelName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopGroupStatus(value: any): SopGroupStatus {
  return {
    isExecutable: value.Result,
    status: value.Status,
    instructStatus: value.InstructStatus,
    wfStatus: value.WFStatus,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseRecordComment(value: any): RecordComment {
  return {
    recordCommentResult: value.Result,
    errorMessage: value.ErrorMessage,
    inputDate: value.InputDate,
    inputUserName: value.InputUserName,
    comment: value.Comment,
    commentType: value.CommentType,
    commentTypeName: value.CommentTypeName,
    instructNo: value.InstructNo,
    itemCode: value.ItemCode,
    itemName: value.ItemName,
    displayLotNo: value.DisplayLotNo,
    processCode: value.ProcessCode,
    processName: value.ProcessName,
    zoneName: value.ZoneName,
    workflowSet: value.WorkflowSet,
    workflowId: value.WorkflowID,
    workflowName: value.WorkflowName,
    batchNo: value.BatchNo,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
    achvOrder: value.AchvOrder,
    sopOrder: value.SOPOrder,
    workflowSetName: value.WorkflowSetName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseCreatedRecordComment(value: any): CreatedRecordComment {
  return {
    createCommentResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    achvOrder: value.AchvOrder,
    comment: value.Comment,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
  };
}

export class SopGroups extends ImmutableMap<string, SopGroup> {
  constructor(sopGroups?: SopGroup[] | Iterable<SopGroup>) {
    super(sopGroup => sopGroup.uid, sopGroups);
  }

  protected clone(sopGroups?: SopGroup[] | Iterable<SopGroup>): this {
    return new SopGroups(sopGroups) as this;
  }

  findByDate(date: string): this {
    if (!date) {
      return this.clone(this.values());
    }
    // Due to performance issues, time zone setting for Day.js is not set.
    const dayjsDate = dayjs(date);
    return this.clone(this.values().filter(sopGroup => dayjsDate.isSameOrAfter(sopGroup.startPlanDate, 'd')));
  }

  findByStatuses(statuses: number[]): this {
    return this.clone(this.values().filter(sopGroup => statuses.includes(sopGroup.wfStatus)));
  }

  findByExecutableStatuses(executableStatuses: number[]): this {
    return this.clone(this.values().filter(sopGroup => executableStatuses.includes(sopGroup.isExecutable)));
  }

  findByOperator(playerCode: string): this {
    if (!this.values().length || !playerCode) {
      return this.clone([]);
    }
    return this.clone(
      this.values().filter(
        sopGroup => sopGroup.playerCode1 === playerCode || sopGroup.playerCode2 === playerCode || sopGroup.playerCode3 === playerCode,
      ),
    );
  }
}
