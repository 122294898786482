import { ICredentials } from '@aws-amplify/core';
import { Observable } from 'rxjs';

import { AuthSession, AuthUser } from '../models/auth.model';

export abstract class AuthGateway {
  abstract currentAuthenticatedUser(): Observable<AuthUser>;
  abstract currentSession(): Observable<AuthSession>;
  // abstract signIn(username: string, password: string): Observable<AuthUser>;
  abstract signIn(): Observable<ICredentials | void>;
  // abstract signIn(): Observable<ICredentials>;
  abstract completeNewPassword(user: AuthUser, password: string): Observable<AuthUser>;
  abstract signOut(): Observable<unknown>;
  abstract forgotPassword(username: string): Observable<unknown>;
  abstract forgotPasswordSubmit(username: string, code: string, password: string): Observable<string>;
  abstract changePassword(user: AuthUser, oldPassword: string, newPassword: string): Observable<'SUCCESS'>;
}
