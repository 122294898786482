import { Observable } from 'rxjs';

import { AuthPayload, AuthState } from '../models/auth.model';

export abstract class AuthUsecase {
  abstract get authState$(): Observable<AuthState>;
  abstract get token$(): Observable<string>;
  abstract get payload$(): Observable<AuthPayload>;

  abstract signIn(): Observable<never>;
  abstract completeNewPassword(password: string): Observable<never>;
  abstract signOut(): void;
  abstract forgotPassword(username: string): Observable<never>;
  abstract forgotPasswordSubmit(username: string, code: string, password: string): Observable<never>;
  abstract setAuthState(state: AuthState): void;
  abstract changePassword(oldPassword: string, newPassword: string): Observable<never>;
}
