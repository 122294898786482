import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from '@aws-amplify/core';
import Bignumber from 'bignumber.js';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// cspell: disable
// TODO: Trial Okta
const region = 'ap-northeast-1';
const userPoolId = 'ap-northeast-1_Ooml63sjq';
const userPoolWebClientId = '4tg51k6mjvind9dp36l3akkftt';
const cognitoDomainPrefix = 'okta-sso-dx-6808';
// cspell: enable

const redirectUrl = window.location.hostname === 'localhost' ? 'http://localhost:4200' : environment.apiUrl;

Amplify.configure({
  ['Auth']: {
    region,
    userPoolId,
    userPoolWebClientId,
    oauth: {
      domain: `${cognitoDomainPrefix}.auth.${region}.amazoncognito.com`,
      // scope: ['aws.cognito.signin.user.admin', 'openid', 'email'],
      scope: ['aws.cognito.signin.user.admin', 'openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    },
  },
});

// const redirectUrl = window.location.hostname === 'localhost' ? 'http://localhost:4200' : environment.apiUrl;

// Amplify.configure({
//   ['Auth']: {
//     region: environment.region,
//     userPoolId: environment.oktaUserPoolId,
//     userPoolWebClientId: environment.oktaUserPoolWebClientId,
//     oauth: {
//       domain: `${environment.oktaUserPoolDomain}.auth.${environment.region}.amazoncognito.com`,
//       scope: ['aws.cognito.signin.user.admin', 'openid', 'email'],
//       redirectSignIn: redirectUrl,
//       redirectSignOut: redirectUrl,
//       responseType: 'code',
//     },
//   },
// });

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);
dayjs.extend(timezone);
dayjs.extend(utc);

Bignumber.config({ EXPONENTIAL_AT: 1000 }); // eslint-disable-line @typescript-eslint/naming-convention

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
