import { SopObjType, SopObjectDefinitionParamater } from './sop-object.model';

export const SOP_WF_PARAM_TYPE = {
  numParam: 1,
  strParam: 2,
  datParam: 3,
  optionParam: 4,
} as const;

export const SOP_WF_NUM_PARAM = {
  branch: {
    doubleCheck: {
      none: 0,
    },
    execType: {
      branch: 0,
      menu: 1,
    },
    labelReferenceMode: {
      latest: 2,
    },
  },
  combineMultipleInput: {
    doubleCheck: {
      none: 0,
    },
  },
  confirmInstruction: {
    doubleCheck: {
      none: 0,
    },
  },
  enterProcessData: {
    dataType: {
      numberType: 0,
      stringType: 1,
    },
    displayOption: {
      display: 0,
      none: 1,
    },
    doubleCheck: {
      none: 0,
    },
    fieldNo: {
      lotUnitOutputVolume: 0,
      serialNumber: 1,
      inputVolume: 4,
      processUnitOutputVolume: 6,
    },
    fieldForSetResult: {
      none: 0,
      yield: 2,
      globalTag: 5,
    },
    getDataMode: {
      instant: 0,
    },
    inputLabelReferenceMode: {
      first: 0,
      sync: 1,
      latest: 2,
    },
    instructionValueSource: {
      fixedValue: 0,
      table: 1,
      label: 2,
      calculateFormula: 3,
    },
    labelReferenceMode: {
      first: 0,
      sync: 1,
      latest: 2,
    },
    limitCheckOption: {
      none: 0,
      fixedRelativeValue: 1,
      percentageRelativeValue: 2,
      stringComparison: 3,
      absoluteValue: 4,
    },
    recordValueInputMode: {
      all: 0,
      alphanumericCharacters: 1,
      numericCharacters: 2,
    },
    recordValueSource: {
      fixedValue: 0,
      manualInput: 1,
      label: 2,
      plc: 4,
      calculateFormula: 5,
      sumOfYield: 6,
      sumOfInputVolume: 7,
      totalLabelValue: 8,
      manualInputDefault: 9,
      fixedValueDefault: 10,
      globalTag: 11,
    },
    yieldProcessingMode: {
      number: 0,
      pallet: 1,
      container: 2,
    },
  },
  inputItem: {
    batchInputOption: {
      bulk: 0,
      division: 1,
    },
    doubleCheck: {
      none: 0,
    },
    inputOption: {
      usageOnly: 1,
    },
    inputTreatMode: {
      input: 0,
    },
    inventoryCheck: {
      siteSelection: 0,
    },
    itemTreatMode: {
      acceptanceAndResultsConfirmed: 2,
    },
  },
  inputTime: {
    doubleCheck: {
      none: 0,
    },
    format: {
      year: 1,
      month: 2,
      yearMonth: 3,
      day: 4,
      monthDay: 6,
      date: 7,
      dayOfWeek: 8,
      monthDayAndDayOfWeek: 14,
      hourMinute: 48,
      monthDayAndHourMinute: 54,
      time: 112,
      dateTime: 119,
    },
    operationTimeSource: {
      manual: 0,
      computerTime: 1,
    },
  },
  manageEquipment: {
    displayOption: {
      display: 0,
      none: 1,
    },
    doubleCheck: {
      none: 0,
    },
    equipmentCodeSource: {
      fixedValue: 0,
      manualInput: 1,
      label: 2,
    },
    labelReferenceMode: {
      latest: 2,
    },
    logBookTreatMode: {
      statusChange: 0,
    },
  },
  showPiPastData: {
    getMode: {
      inner: 1,
      outer: 2,
      complement: 3,
    },
    isShowPage: {
      display: 0,
      none: 1,
    },
  },
} as const;

export const getParamVal = (param: SopObjectDefinitionParamater | undefined): string | number | undefined => {
  if (param == null) {
    return '';
  }
  switch (param.wfParamType) {
    case SOP_WF_PARAM_TYPE.numParam: {
      if (param.wfNumParam === 'NULL') {
        return;
      }
      return param.wfNumParam;
    }
    case SOP_WF_PARAM_TYPE.optionParam: {
      const num = parseFloat(param.wfNumParam);
      if (isNaN(num)) {
        return;
      }
      return num;
    }
    case SOP_WF_PARAM_TYPE.strParam:
      return param.wfStrParam;
    case SOP_WF_PARAM_TYPE.datParam:
      return param.wfDatParam;
    default:
      return '';
  }
};

type BranchParams = {
  branchPlaceLabel1?: string;
  branchPlaceLabel2?: string;
  branchPlaceLabel3?: string;
  branchPlaceLabel4?: string;
  branchPlaceLabel5?: string;
  branchPlaceLabel6?: string;
  condition1?: string;
  condition2?: string;
  condition3?: string;
  condition4?: string;
  condition5?: string;
  condition6?: string;
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.branch.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.branch.doubleCheck];
  execType?: (typeof SOP_WF_NUM_PARAM.branch.execType)[keyof typeof SOP_WF_NUM_PARAM.branch.execType];
  executableRole?: string;
  labelReferenceMode?: (typeof SOP_WF_NUM_PARAM.branch.labelReferenceMode)[keyof typeof SOP_WF_NUM_PARAM.branch.labelReferenceMode];
};

type CombineMultipleInputParams = {
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.combineMultipleInput.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.combineMultipleInput.doubleCheck];
  executableRole?: string;
  fromLabelName?: string;
  toLabelName?: string;
};

type ConfirmInstructionParams = {
  confirmContents?: string;
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.confirmInstruction.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.confirmInstruction.doubleCheck];
  executableRole?: string;
};

type EnterProcessDataParams = {
  aboveUpperPoint?: string;
  belowLowerPoint?: string;
  dataType?: (typeof SOP_WF_NUM_PARAM.enterProcessData.dataType)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.dataType];
  displayOption?: (typeof SOP_WF_NUM_PARAM.enterProcessData.displayOption)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.displayOption];
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.enterProcessData.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.doubleCheck];
  executableRole?: string;
  fieldForSetResult?: (typeof SOP_WF_NUM_PARAM.enterProcessData.fieldForSetResult)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.fieldForSetResult];
  fieldNo?: (typeof SOP_WF_NUM_PARAM.enterProcessData.fieldNo)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.fieldNo];
  fixedValue?: string;
  getDataMode?: (typeof SOP_WF_NUM_PARAM.enterProcessData.getDataMode)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.getDataMode];
  inputLabelReferenceMode?: (typeof SOP_WF_NUM_PARAM.enterProcessData.inputLabelReferenceMode)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.inputLabelReferenceMode];
  inputOrder?: string;
  instructionValueLabelName?: string;
  instructionValueOperationFormula?: string;
  instructionValueSource?: (typeof SOP_WF_NUM_PARAM.enterProcessData.instructionValueSource)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.instructionValueSource];
  itemCode?: string;
  itemCodeForSetResult?: string;
  labelReferenceMode?: (typeof SOP_WF_NUM_PARAM.enterProcessData.labelReferenceMode)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.labelReferenceMode];
  limitCheckOption?: (typeof SOP_WF_NUM_PARAM.enterProcessData.limitCheckOption)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.limitCheckOption];
  lowerPoint?: string;
  operationFormula?: string;
  recordValue?: string;
  recordValueInputMode?: (typeof SOP_WF_NUM_PARAM.enterProcessData.recordValueInputMode)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.recordValueInputMode];
  recordValueInputOrder?: string;
  recordValueLabelName?: string;
  recordValueSource?: (typeof SOP_WF_NUM_PARAM.enterProcessData.recordValueSource)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.recordValueSource];
  sumProductNameCode?: string;
  tagName?: string;
  tagNameForSetResult?: string;
  underPointNumber?: string;
  unit?: string;
  upperPoint?: string;
  yieldProcessingMode?: (typeof SOP_WF_NUM_PARAM.enterProcessData.yieldProcessingMode)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.yieldProcessingMode];
};

type InputItemParams = {
  batchInputOption?: (typeof SOP_WF_NUM_PARAM.inputItem.batchInputOption)[keyof typeof SOP_WF_NUM_PARAM.inputItem.batchInputOption];
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.inputItem.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.inputItem.doubleCheck];
  executableRole?: string;
  inputItems?: string;
  inputItemLabelName?: string;
  inputOption?: (typeof SOP_WF_NUM_PARAM.inputItem.inputOption)[keyof typeof SOP_WF_NUM_PARAM.inputItem.inputOption];
  inputTreatMode?: (typeof SOP_WF_NUM_PARAM.inputItem.inputTreatMode)[keyof typeof SOP_WF_NUM_PARAM.inputItem.inputTreatMode];
  inventoryCheck?: (typeof SOP_WF_NUM_PARAM.inputItem.inventoryCheck)[keyof typeof SOP_WF_NUM_PARAM.inputItem.inventoryCheck];
  itemTreatMode?: (typeof SOP_WF_NUM_PARAM.inputItem.itemTreatMode)[keyof typeof SOP_WF_NUM_PARAM.inputItem.itemTreatMode];
  recipeOrder?: string;
  underPointNumber?: string;
};

type InputTimeParams = {
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.inputTime.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.inputTime.doubleCheck];
  executableRole?: string;
  format?: (typeof SOP_WF_NUM_PARAM.inputTime.format)[keyof typeof SOP_WF_NUM_PARAM.inputTime.format];
  operationTimeSource?: (typeof SOP_WF_NUM_PARAM.inputTime.operationTimeSource)[keyof typeof SOP_WF_NUM_PARAM.inputTime.operationTimeSource];
};

type ManageEquipmentParams = {
  displayOption?: (typeof SOP_WF_NUM_PARAM.manageEquipment.displayOption)[keyof typeof SOP_WF_NUM_PARAM.manageEquipment.displayOption];
  doubleCheck?: (typeof SOP_WF_NUM_PARAM.manageEquipment.doubleCheck)[keyof typeof SOP_WF_NUM_PARAM.manageEquipment.doubleCheck];
  equipmentCode?: string;
  equipmentCodeSource?: (typeof SOP_WF_NUM_PARAM.manageEquipment.equipmentCodeSource)[keyof typeof SOP_WF_NUM_PARAM.manageEquipment.equipmentCodeSource];
  executableRole?: string;
  inputLabelName?: string;
  labelReferenceMode?: (typeof SOP_WF_NUM_PARAM.manageEquipment.labelReferenceMode)[keyof typeof SOP_WF_NUM_PARAM.manageEquipment.labelReferenceMode];
  logBookTreatMode?: (typeof SOP_WF_NUM_PARAM.manageEquipment.logBookTreatMode)[keyof typeof SOP_WF_NUM_PARAM.manageEquipment.logBookTreatMode];
  updatedStatus?: string;
};

type ShowPiPastDataParams = {
  tagName?: string;
  baseTimeLabelName?: string;
  offSetValue?: number;
  getMode?: (typeof SOP_WF_NUM_PARAM.showPiPastData.getMode)[keyof typeof SOP_WF_NUM_PARAM.showPiPastData.getMode];
  isShowPage?: (typeof SOP_WF_NUM_PARAM.showPiPastData.isShowPage)[keyof typeof SOP_WF_NUM_PARAM.showPiPastData.isShowPage];
};

export type SopParams = BranchParams &
  CombineMultipleInputParams &
  ConfirmInstructionParams &
  EnterProcessDataParams &
  InputItemParams &
  InputTimeParams &
  ManageEquipmentParams &
  ShowPiPastDataParams;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopParams(objType: SopObjType, value: any): SopParams {
  switch (objType) {
    case 'Branch':
      return parseBranchParams(value) as SopParams;
    case 'CombineMultipleInput':
      return parseCombineMultipleInputParams(value) as SopParams;
    case 'ConfirmInstruction':
      return parseConfirmInstructionParams(value) as SopParams;
    case 'EnterProcessData':
      return parseEnterProcessDataParams(value) as SopParams;
    case 'InputItem':
      return parseInputItemParams(value) as SopParams;
    case 'InputTime':
      return parseInputTimeParams(value) as SopParams;
    case 'ManageEquipment':
      return parseManageEquipmentParams(value) as SopParams;
    case 'ShowPIPastData':
      return parseShowPiPastDataParams(value) as SopParams;
    default:
      return value as SopParams;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseBranchParams(value: any): BranchParams {
  return {
    branchPlaceLabel1: value.BranchPlaceLabel1,
    branchPlaceLabel2: value.BranchPlaceLabel2,
    branchPlaceLabel3: value.BranchPlaceLabel3,
    branchPlaceLabel4: value.BranchPlaceLabel4,
    branchPlaceLabel5: value.BranchPlaceLabel5,
    branchPlaceLabel6: value.BranchPlaceLabel6,
    condition1: value.Condition1,
    condition2: value.Condition2,
    condition3: value.Condition3,
    condition4: value.Condition4,
    condition5: value.Condition5,
    condition6: value.Condition6,
    doubleCheck: value.DoubleCheck,
    execType: value.ExecType,
    executableRole: value.ExecutableRole,
    labelReferenceMode: value.LabelReferenceMode,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseCombineMultipleInputParams(value: any): CombineMultipleInputParams {
  return {
    doubleCheck: value.DoubleCheck,
    executableRole: value.ExecutableRole,
    fromLabelName: value.FromLabelName,
    toLabelName: value.ToLabelName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseConfirmInstructionParams(value: any): ConfirmInstructionParams {
  return {
    confirmContents: value.ConfirmContents,
    doubleCheck: value.DoubleCheck,
    executableRole: value.ExecutableRole,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseEnterProcessDataParams(value: any): EnterProcessDataParams {
  return {
    aboveUpperPoint: value.AboveUpperPoint,
    belowLowerPoint: value.BelowLowerPoint,
    dataType: value.DataType,
    displayOption: value.DisplayOption,
    doubleCheck: value.DoubleCheck,
    executableRole: value.ExecutableRole,
    fieldForSetResult: value.FieldForSetResult,
    fieldNo: value.FieldNo,
    fixedValue: value.FixedValue,
    getDataMode: value.GetDataMode,
    inputLabelReferenceMode: value.InputLabelReferenceMode,
    inputOrder: value.InputOrder,
    instructionValueLabelName: value.InstructionValueLabelName,
    instructionValueOperationFormula: value.InstructionValueOperationFormula,
    instructionValueSource: value.InstructionValueSource,
    itemCode: value.ItemCode,
    itemCodeForSetResult: value.ItemCodeForSetResult,
    labelReferenceMode: value.LabelReferenceMode,
    limitCheckOption: value.LimitCheckOption,
    lowerPoint: value.LowerPoint,
    operationFormula: value.OperationFormula,
    recordValue: value.RecordValue,
    recordValueInputMode: value.RecordValueInputMode,
    recordValueInputOrder: value.RecordValueInputOrder,
    recordValueLabelName: value.RecordValueLabelName,
    recordValueSource: value.RecordValueSource,
    sumProductNameCode: value.SumProductNameCode,
    tagName: value.TAGName,
    tagNameForSetResult: value.TagNameForSetResult,
    underPointNumber: value.UnderPointNumber,
    unit: value.Unit,
    upperPoint: value.UpperPoint,
    yieldProcessingMode: value.YieldProcessingMode,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseInputItemParams(value: any): InputItemParams {
  return {
    batchInputOption: value.BatchInputOption,
    doubleCheck: value.DoubleCheck,
    executableRole: value.ExecutableRole,
    inputItems: value.InputItems,
    inputItemLabelName: value.InputItemLabelName,
    inputOption: value.InputOption,
    inputTreatMode: value.InputTreatMode,
    inventoryCheck: value.InventoryCheck,
    itemTreatMode: value.ItemTreatMode,
    recipeOrder: value.RecipeOrder,
    underPointNumber: value.UnderPointNumber,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseInputTimeParams(value: any): InputTimeParams {
  return {
    doubleCheck: value.DoubleCheck,
    executableRole: value.ExecutableRole,
    format: value.Format,
    operationTimeSource: value.OperationTimeSource,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseManageEquipmentParams(value: any): ManageEquipmentParams {
  return {
    displayOption: value.DisplayOption,
    doubleCheck: value.DoubleCheck,
    equipmentCode: value.EquipmentCode,
    equipmentCodeSource: value.EquipmentCodeSource,
    executableRole: value.ExecutableRole,
    inputLabelName: value.InputLabelName,
    labelReferenceMode: value.LabelReferenceMode,
    logBookTreatMode: value.LogBookTreatMode,
    updatedStatus: value.UpdatedStatus,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseShowPiPastDataParams(value: any): ShowPiPastDataParams {
  return {
    baseTimeLabelName: value.BaseTimeLabelName,
    getMode: value.GetMode,
    isShowPage: value.IsShowPage,
    offSetValue: value.OffSetValue,
    tagName: value.TagName,
  };
}
