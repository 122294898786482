import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { LibModule, httpInterceptorProviders } from '@daikin-tic/dxone-com-lib';

import { MesRequestInteractor } from './interactors/mes-request.interactor';
import { TaskInteractor } from './interactors/task.interactor';
import { CustomJsonInterceptor, CustomJsonParser, JsonParser } from './interceptors/custom-json.interceptor';
import { MesRequestInterceptor } from './interceptors/mes-request.interceptor';
import { DeviationService } from './services/deviation.service';
import { SopGroupService } from './services/sop-group.service';
import { SopObjectService } from './services/sop-object.service';
import { DeviationGateway } from './usecases/deviation.gateway';
import { MesRequestUsecase } from './usecases/mes-request.usecase';
import { SopGroupGateway } from './usecases/sop-group.gateway';
import { SopObjectGateway } from './usecases/sop-object.gateway';
import { TaskUsecase } from './usecases/task.usecase';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MesRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomJsonInterceptor, multi: true },
    ...httpInterceptorProviders,
    { provide: JsonParser, useClass: CustomJsonParser },
    { provide: MesRequestUsecase, useClass: MesRequestInteractor },
    { provide: TaskUsecase, useClass: TaskInteractor },
    { provide: DeviationGateway, useClass: DeviationService },
    { provide: SopGroupGateway, useClass: SopGroupService },
    { provide: SopObjectGateway, useClass: SopObjectService },
  ],
  imports: [CommonModule, LibModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only.');
    }
  }
}
