import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { Observable, defer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthPayload, AuthSession, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  currentAuthenticatedUser(): Observable<AuthUser> {
    return defer(() => Auth.currentAuthenticatedUser()).pipe(
      tap(result => {
        console.log('currentAuthenticatedUser');
        console.log(result);
      }),
    );
  }

  currentSession(): Observable<AuthSession> {
    return defer(() => Auth.currentSession()).pipe(
      tap(result => {
        console.log('currentSession');
        console.log(result);
      }),
      map(session => ({ token: session.getIdToken().getJwtToken(), payload: session.getIdToken().payload as AuthPayload })),
    );
  }

  signIn(): Observable<ICredentials | void> {
    // return defer(() => Auth.federatedSignIn({ customProvider: 'Okta-SAML' }));
    return defer(() => Auth.federatedSignIn({ customProvider: 'okta-saml' }));
  }

  completeNewPassword(user: AuthUser, password: string): Observable<AuthUser> {
    return defer(() => Auth.completeNewPassword(user, password, false));
  }

  signOut(): Observable<unknown> {
    // TODO: オプションどうするか？
    return defer(() => Auth.signOut({ global: true }));
  }

  forgotPassword(username: string): Observable<unknown> {
    return defer(() => Auth.forgotPassword(username));
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<string> {
    return defer(() => Auth.forgotPasswordSubmit(username, code, password));
  }

  changePassword(user: AuthUser, oldPassword: string, newPassword: string): Observable<'SUCCESS'> {
    return defer(() => Auth.changePassword(user, oldPassword, newPassword));
  }
}
