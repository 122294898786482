import { Observable } from 'rxjs';

import { AllocationNotice } from '../models/allocation-notice.model';
import { Deviation } from '../models/deviation.model';
import {
  CreatedRecordComment,
  Order,
  OrderReadParams,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SopGroupAchievementReadParams,
  SopGroupReadParams,
  SopGroups,
} from '../models/sop-group.model';
import {
  ConfirmationReadParams,
  EquipmentReadParams,
  InstructReadParams,
  InventoryReadParams,
  PiPastDataReadParams,
  ProcessInputAchievementsReadParams,
  ProcessInputReadParams,
  ProcessInputVolumeReadParams,
  ProcessOutputVolumeReadParams,
  SopFormulaReadParams,
  SopLabelValueReadParams,
  SopObjectAchievementUpdateParams,
  SopObjectDefinitionReadParams,
  SopObjectInputItemUpdateParams,
  SopObjectLogBookUpdateParams,
  SopObjectMultipleUpdateParams,
  SopObjectReadParams,
  SopObjectSkipParams,
  SopObjectUpdateParams,
  SopObjects,
  SumOfInputVolumeReadParams,
  SumOfYieldReadParams,
  TagValueReadParams,
  UpdatedSopObject,
  UpdatedSopObjectAchievement,
  UpdatedSopObjectInputItem,
  UpdatedSopObjectLogBook,
  UpdatedSopObjectMultiple,
  UpdatedSopObjectSkip,
} from '../models/sop-object.model';

export abstract class TaskUsecase {
  abstract get sopGroups$(): Observable<SopGroups>;
  abstract get sopObjects$(): Observable<SopObjects>;
  abstract get date$(): Observable<string>;
  abstract get operators$(): Observable<string[]>;
  abstract get showUnknownOperators$(): Observable<boolean>;
  abstract get statuses$(): Observable<number[]>;
  abstract get executableStatuses$(): Observable<number[]>;
  abstract get deviations$(): Observable<Deviation[]>;
  abstract get allocationNotice$(): Observable<AllocationNotice>;

  abstract changeDate(date: string): void;
  abstract changeOperators(operators: string[]): void;
  abstract changeShowUnknownOperators(showUnknownOperators: boolean): void;
  abstract changeStatuses(statuses: number[]): void;
  abstract changeExecutableStatuses(executableStatuses: number[]): void;

  abstract listOrders(params: OrderReadParams): Observable<Order[]>;
  abstract listSopGroupAchievements(params: SopGroupAchievementReadParams, priority: number): Observable<never>;
  abstract listSopObjectAchievements(params: SopGroupReadParams): Observable<never>;
  abstract listGroupRecordComments(params: RecordCommentReadParams, priority: number): Observable<never>;
  abstract listDeviations(): Observable<never>;
  abstract getSopGroupStatus(params: SopGroupReadParams, priority: number): Observable<never>;
  abstract createGroupRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment>;

  abstract listSopObjectDefinitions(params: SopObjectDefinitionReadParams): Observable<never>;
  abstract listConfirmations(params: ConfirmationReadParams, objId: string): Observable<never>;
  abstract listEquipments(params: EquipmentReadParams, objId: string): Observable<never>;
  abstract listProcessInputs(params: ProcessInputReadParams): Observable<never>;
  abstract listProcessInputAchievements(params: ProcessInputAchievementsReadParams): Observable<never>;
  abstract listInventories(params: InventoryReadParams, priority: number): Observable<never>;
  abstract listObjectRecordComments(params: RecordCommentReadParams, priority: number): Observable<never>;
  abstract getSopObjectAchievement(params: SopObjectReadParams): Observable<never>;
  abstract getSumOfYield(params: SumOfYieldReadParams, objId: string): Observable<never>;
  abstract getSumOfInputVolume(params: SumOfInputVolumeReadParams, objId: string): Observable<never>;
  abstract getInstruct(params: InstructReadParams, objId: string): Observable<never>;
  abstract getProcessInputVolume(params: ProcessInputVolumeReadParams, objId: string): Observable<never>;
  abstract getProcessOutputVolume(params: ProcessOutputVolumeReadParams, objId: string): Observable<never>;
  abstract getSopObjectStatus(params: SopObjectReadParams, priority: number): Observable<never>;
  abstract getSopLabelValue(
    params: SopLabelValueReadParams,
    priority: number,
    objId: string,
    labelId: string,
    hideProgress?: boolean,
  ): Observable<never>;
  abstract getTagValue(params: TagValueReadParams, priority: number, objId: string): Observable<never>;
  abstract calculateFormula(
    params: SopFormulaReadParams,
    priority: number,
    objId: string,
    formulaId: string,
    hideProgress?: boolean,
  ): Observable<never>;
  abstract getPiPastData(params: PiPastDataReadParams, priority: number, objId: string): Observable<never>;
  abstract updateSopObject(params: SopObjectUpdateParams): Observable<UpdatedSopObject>;
  abstract updateSopObjectLogBook(params: SopObjectLogBookUpdateParams): Observable<UpdatedSopObjectLogBook>;
  abstract updateSopObjectMultiple(params: SopObjectMultipleUpdateParams[]): Observable<UpdatedSopObjectMultiple[]>;
  abstract updateSopObjectInputItem(params: SopObjectInputItemUpdateParams): Observable<UpdatedSopObjectInputItem>;
  abstract updateSopObjectSkip(params: SopObjectSkipParams): Observable<UpdatedSopObjectSkip>;
  abstract updateSopObjectAchievement(params: SopObjectAchievementUpdateParams): Observable<UpdatedSopObjectAchievement>;
  abstract createObjectRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment>;

  abstract clearSopGroupComments(): void;
  abstract clearSopObjects(): void;
  abstract clearSopObjectComments(): void;
  abstract clearSopObjectReferenceValue(): void;

  abstract reflectResponse(apiName: string, request: unknown, response: unknown): void;
  abstract reflectCancelRequest(apiName: string, request: unknown): void;
}
