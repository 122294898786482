import { Observable } from 'rxjs';

import {
  CreatedRecordComment,
  RecordComment,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SopObjectAchievement,
} from '../models/sop-group.model';
import {
  Confirmation,
  ConfirmationReadParams,
  Equipment,
  EquipmentReadParams,
  Instruct,
  InstructReadParams,
  Inventory,
  InventoryReadParams,
  PiPastData,
  PiPastDataReadParams,
  ProcessInput,
  ProcessInputAchievement,
  ProcessInputAchievementsReadParams,
  ProcessInputReadParams,
  ProcessInputVolume,
  ProcessInputVolumeReadParams,
  ProcessOutputVolume,
  ProcessOutputVolumeReadParams,
  SopFormula,
  SopFormulaReadParams,
  SopLabelValue,
  SopLabelValueReadParams,
  SopObjectAchievementUpdateParams,
  SopObjectDefinition,
  SopObjectDefinitionReadParams,
  SopObjectInputItemUpdateParams,
  SopObjectLogBookUpdateParams,
  SopObjectMultipleUpdateParams,
  SopObjectReadParams,
  SopObjectSkipParams,
  SopObjectStatus,
  SopObjectUpdateParams,
  SumOfInputVolume,
  SumOfInputVolumeReadParams,
  SumOfYield,
  SumOfYieldReadParams,
  TagValue,
  TagValueReadParams,
  UpdatedSopObject,
  UpdatedSopObjectAchievement,
  UpdatedSopObjectInputItem,
  UpdatedSopObjectLogBook,
  UpdatedSopObjectMultiple,
  UpdatedSopObjectSkip,
} from '../models/sop-object.model';

export abstract class SopObjectGateway {
  abstract listSopObjectDefinitions(params: SopObjectDefinitionReadParams): Observable<SopObjectDefinition[]>;
  abstract listConfirmations(params: ConfirmationReadParams): Observable<Confirmation[]>;
  abstract listEquipments(params: EquipmentReadParams): Observable<Equipment[]>;
  abstract listProcessInputs(params: ProcessInputReadParams): Observable<ProcessInput[]>;
  abstract listProcessInputAchievements(params: ProcessInputAchievementsReadParams): Observable<ProcessInputAchievement[]>;
  abstract listInventories(params: InventoryReadParams, priority: number): Observable<Inventory[]>;
  abstract listObjectRecordComments(params: RecordCommentReadParams, priority: number): Observable<RecordComment[]>;
  abstract getSopObjectAchievement(params: SopObjectReadParams): Observable<SopObjectAchievement[]>;
  abstract getSumOfYield(params: SumOfYieldReadParams): Observable<SumOfYield[]>;
  abstract getSumOfInputVolume(params: SumOfInputVolumeReadParams): Observable<SumOfInputVolume[]>;
  abstract getInstruct(params: InstructReadParams): Observable<Instruct[]>;
  abstract getProcessInputVolume(params: ProcessInputVolumeReadParams): Observable<ProcessInputVolume[]>;
  abstract getProcessOutputVolume(params: ProcessOutputVolumeReadParams): Observable<ProcessOutputVolume[]>;
  abstract getSopObjectStatus(params: SopObjectReadParams, priority: number): Observable<SopObjectStatus>;
  abstract getSopLabelValue(
    params: SopLabelValueReadParams,
    priority: number,
    objId: string,
    labelId: string,
    hideProgress?: boolean,
  ): Observable<SopLabelValue>;
  abstract getTagValue(params: TagValueReadParams, priority: number, objId: string): Observable<TagValue>;
  abstract calculateFormula(
    params: SopFormulaReadParams,
    priority: number,
    objId: string,
    formulaId: string,
    hideProgress?: boolean,
  ): Observable<SopFormula>;
  abstract getPiPastData(params: PiPastDataReadParams, priority: number, objId: string): Observable<PiPastData>;
  abstract updateSopObject(params: SopObjectUpdateParams): Observable<UpdatedSopObject>;
  abstract updateSopObjectLogBook(params: SopObjectLogBookUpdateParams): Observable<UpdatedSopObjectLogBook>;
  abstract updateSopObjectMultiple(params: SopObjectMultipleUpdateParams[]): Observable<UpdatedSopObjectMultiple[]>;
  abstract updateSopObjectInputItem(params: SopObjectInputItemUpdateParams): Observable<UpdatedSopObjectInputItem>;
  abstract updateSopObjectSkip(params: SopObjectSkipParams): Observable<UpdatedSopObjectSkip>;
  abstract updateSopObjectAchievement(params: SopObjectAchievementUpdateParams): Observable<UpdatedSopObjectAchievement>;
  abstract createObjectRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment>;
}
